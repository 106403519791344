import React, { useState, useEffect, useRef } from "react"
import { Container, Intro, Services, Price, Questions, Contact } from "./styles.js"
import { SectionTitle } from '../titles/index.js'
import Modal from './modal.js'
import documents from '../../images/doc_leg.png'
import idCard from '../../images/id-card.png'
import bussiness from '../../images/bussiness.png'
import site from '../../images/site.png'
import clock from '../../images/clock.png'
import appeal from '../../images/appeal.png'
import euCard from '../../images/eu-card.png'
import bluecard from '../../images/bluecard.png'
import Slide from 'react-reveal/Slide'
import ModalForm from './modalMain.js'
import QuestionsAnswers from "./questions.js"


const LegalizationPage = () => {
    const [isAnswerOppened, setIsAnswerOppened] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    function seeAnswer(e) {
        setIsAnswerOppened(!isAnswerOppened)
    }

    function executeScroll(myRef) {
        console.log(myRef)
        window.scroll({
          top: myRef.current.offsetTop,
          behavior: "smooth",
        })
      }

    const myRef = useRef(null)

    function openModal() {
        setIsModalOpen(true)
        document.body.style.overflowY = "clip"
    }

    return (
        <Container>
            <Slide top>
                <Intro>
                    <div className='intro_content'>
                        <h2>Поможем с легализацией пребывания, а также во многих других вопросах! <div className="mobile_btn" onClick={() => executeScroll(myRef)}><span className="arrow"/></div></h2>
                        <Modal className='form' />
                    </div>
                </Intro>
            </Slide>
            <div className="mobile_form" ref={myRef}>
               <Modal /> 
            </div>
            <Services >
                <SectionTitle>Наши услуги</SectionTitle>
                <div className="services_block">
                    <div>
                        <img src={idCard} alt="img" />
                        <p>Вид на жительство (Karta czasowego i stałego pobytu)</p>
                    </div>
                    <div>
                        <img src={documents} alt="img" />
                        <p>Приглашения</p>
                    </div>
                    <div>
                        <img src={clock} alt="img" />
                        <p>Ускорение процесса</p>
                    </div>
                    <div>
                        <img src={bussiness} alt="img" />
                        <p>Открытие фирмы</p>
                    </div>
                    <div>
                        <img src={euCard} alt="img" />
                        <p>Карта резидента ЕС</p>
                    </div>
                    <div>
                        <img src={appeal} alt="img" />
                        <p>Аппеляция</p>
                    </div>
                    <div>
                        <img src={bluecard} alt="img" />
                        <p>Blue card</p>
                    </div>
                    <div>
                        <img src={site} alt="img" />
                        <p>Profil zaufany</p>
                    </div>
                </div>
            </Services>
            <ModalForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
            <Price>
                <SectionTitle>Ценник услуг</SectionTitle>
                <p>Цена за карту стартует от <span>2000 зл.</span> К сожалению мы не можем указать точных цен на сайте, каждое дело уникально и требует анализа, поэтому приглашаем Вас на консультацию!</p>
                <div className="consultation">
                    <div className="consultation_block">
                        <div>
                            <div className="title">Бесплатная консультация онлайн</div>
                            <div className="list">
                                - Общая информация по легализации пребывания <br />
                                - Информация о необходимых документах
                            </div>
                        </div>
                        <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Оставить заявку</button>
                    </div>
                    <div className="consultation_block">
                        <div className="title">Анализ ситуации в офисе</div>
                        <div className="list">
                            - Оценка ситуации по легализации пребывания<br />
                            - Предложение различных опций решения <br />
                            - Анализ документов <br />
                            - Ответы на все Ваши вопросы
                        </div>
                        <div className="price"><div>Стоимость: <span>200 zł</span></div> <button onClick={openModal}>Заказать</button></div>
                    </div>
                </div>
            </Price>
            {/* <Questions>
                <SectionTitle>Часто задаваемые вопросы</SectionTitle>
                <QuestionsAnswers question={'Сколько длится процесс получения Карты Побыта?'} answer={'......'} />
                <QuestionsAnswers question={'question'} answer={'answer'}/>
            </Questions> */}
            <Contact>
                <div className="line" />
                <p>Вы можете также обраться к нам с любым вопросом написав в viber/whatsapp/telegram по номеру  <a href="tel:+790535 315"> +48 790 535 315</a></p>
                <div className="line" />
            </Contact>
        </Container >
    )
}

export default LegalizationPage